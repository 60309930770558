import React, {
    useState,
    // useContext,
} from "react";
import * as Yup from "yup";
import { Formik, Form, useField } from "formik";
import Cookies from "js-cookie"; // @todo cookies for gatsby. ditto with navigate/history could wrap these in components.
// import { useHistory } from "react-router-dom"; // @todo import { navigate } from 'gatsby'
import { navigate } from "gatsby"
import { useMutation } from "@apollo/client";

import { Loader } from "../../elements/loader"

import "../wrapper/formWrapper.sass"

const YolkFormInput = ({ label, ...props }) => {
    const [field, meta] = useField(props);
    return (
        <React.Fragment>
            <div className="inputSection">
                {label ? (
                    <div className="labelWrap" name={props.name}>
                        <label htmlFor={props.id || props.name}>{label}</label>
                    </div>
                ) : null}
                <div className="inputZone" name={props.name} id={props.name}>
                    {meta.touched && meta.error ? (
                        <span className="error">{meta.error}</span>
                    ) : (
                        <span className="error">&nbsp;</span>
                    )}
                    <input className={props.type} {...field} {...props} />
                </div>
            </div>
        </React.Fragment>
    );
};

// construct all inputs for display in the form
const FormInputsDisplay = (inputs) => {
    const display = [];

    for (let k in inputs) {
        display.push(
            <YolkFormInput
                name={inputs[k].name}
                type={inputs[k].type}
                placeholder={inputs[k].placeholder}
                label={inputs[k].label}
            />
        );
    }
    return display;
};

const FormWrapper = (props) => {

    const toke = props.toke ? true : false

    const validationSchema = Yup.object().shape(props.schema);
    const fields = Object.keys(props.schema);
    const { inputFields } = props;
    const initialValues = {};
    fields.forEach((field) => {
        initialValues[field] = "";
        !(field in inputFields) && (inputFields[field] = {});
        inputFields[field].name = field;
        if (typeof inputFields[field].type === "undefined") {
            inputFields[field].type = "text";
        }
    });
    const variables = (values) => {
        const entries = {};
        if (toke) {
            entries.toke = props.toke
        }
        fields.forEach((field) => {
            entries[field] = values[field];
        });
        return entries;
    };

    // const history = useHistory();
    const [submitResponse, setSubmitResponse] = useState("");

    const [queryDb] = useMutation(props.query)
    // const [queryDb, {loading}] = useMutation(props.query)
    // if (loading) { return <Loader />}

    const showError = (msg) => {
        if (msg != "") {
            setSubmitResponse(msg)
        } else {
            setSubmitResponse(props.submitErrMsg)
        }
    }

    const handleSubmit = (values, { setSubmitting }) => {

        queryDb({
            variables: variables(values)
        }).then((response) => {
            if (response.data[props.dbQueryName]) {
                document.getElementById("replace").innerHTML = ""
                document.getElementById("title").innerHTML = "Thank you"
                document.getElementById("tagline").innerHTML = "We'll be in touch shortly."
                
            } else {
                // if env=development
                    // console.log("failed")
                showError()
            }

            // @todo handle api down.


            // if (response.data[props.dbQueryName] === "true") {
            //     navigate(props.successRoute)
            // } else if (response.data[props.dbQueryName] === "false") {
            //     setSubmitResponse(props.submitErrMsg ? props.submitErrMsg : ' ')
            // } else if (response.data[props.dbQueryName] === "unauthorized") {
            //     setSubmitResponse("Acess denied")
            //     Cookies.remove("_ylk_tk")
            //     // history.push("/login")
            // }
            // else {        
            //     if(props.dbQueryName === "signup" || props.dbQueryName === "login") {
            //         Cookies.remove("_ylk_tk")
            //         var inOneHour = new Date(new Date().getTime() + 60 * 60 * 1000);
            //         Cookies.set("_ylk_tk", response.data[props.dbQueryName], { expires: inOneHour })
            //     }
            //     navigate(props.successRoute)
            // }

        }).catch((error) => {
            // if (error.message === "Network request failed") {
            //     showError("Looks like we have egg on our face. Please wait a few minutes and try again.")
            // }

            // @todo handle different errors.
            // if env=development

            // if (error.message === "Failed to fetch") {
            //     setSubmitResponse(props.submitErrMsg)
            // }
            // setSubmitResponse(props.submitErrMsg)

        })
        setSubmitting(false);
    };

    return (
        <div className="formWrapper">
            <div className="submitResponsePlaceholder">
                {submitResponse ? (
                    <div className="submitResponse">{submitResponse}</div>
                ) : null}
            </div>
            <Formik
                onSubmit={handleSubmit}
                validationSchema={validationSchema}
                initialValues={initialValues}
            >
                <Form id="replace">
                    {FormInputsDisplay(inputFields)}
                    <button type="submit">{props.ctaText}</button>
                </Form>
            </Formik>
            {props.sundries ? <div className="sundries">{props.sundries}</div> : ""}
        </div>
    );
};

export { FormWrapper };

import { gql } from '@apollo/client'

const QUERY = gql`
    mutation RequestDemo(
        $email: String!
        $firstName: String!
        $lastName: String!
        $companyName: String!
        $companyUrl: String
        $phone: String!
    ) {
        requestDemo(input: {
            email: $email,
            firstName: $firstName,
            lastName: $lastName,
            companyName: $companyName,
            companyUrl: $companyUrl,
            phone: $phone,
        })
    }
`

export { QUERY }
import React from "react";
import * as Yup from "yup";
import "yup-phone";
import { ApolloProvider } from "@apollo/client"
import { client } from "../../app/provider/apollo/client"

import { QUERY } from "../../queries/demo";
import { FormWrapper } from "./demoFormWrapper"

import "./demoForm.sass"

const DemoForm = (props) => {

    const schema = {
        email: Yup.string()
            .email("Invalid Email Address")
            .required("*Required"),
        firstName: Yup.string().required("*Required"),
        lastName: Yup.string().required("*Required"),
        companyName: Yup.string().required("*Required"),
        companyUrl: Yup.string()    
            .matches(
                /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
                'Must be a valid url'
            ),
        phone: Yup.string()
            .phone("","","Must be a valid phone number")
            .required("*Required"),
    };

    const inputFields = {
        email: {
            // label: "Email:",
            placeholder: "Email",
        },
        phone: {
            // label: "Phone:",
            placeholder: "Phone",
        },
        firstName: {
            // label: "Name:",
            placeholder: "First name",
        },
        lastName: {
            placeholder: "Last name"
        },
        companyName: {
            // label: "Company:",
            placeholder: "Company",
        },
        companyUrl: {
            placeholder: "Website"
        },
    };

    return (
        <ApolloProvider client={client}>
            <FormWrapper
                ctaText="Schedule your Demo"
                dbQueryName="requestDemo"
                successRoute="/platform/demo/thank-you"
                submitErrMsg="Please try again."
                inputFields={inputFields}
                schema={schema}
                query={QUERY}
            />
        </ApolloProvider>
    );
};

export { DemoForm };
import React from "react"

import { ModalLayout } from "../../layouts/modal"

import { DemoForm } from "../../forms/demo"

const Page = () => {

    const content = {}
    content.htmlTitle = `See How It Works`
    content.pageTitle = `See what Yolk can do for you.`
    content.pageTagline = `Let us know a little about you and we'll be in touch.`

    const form = <DemoForm />;

    return (
        <ModalLayout {...content} form={form} />
    )

}

export default Page